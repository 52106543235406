<template>
  <div class="container">
    <div class="reg">
      <!-- 步骤条 -->
      <el-steps class="step-title" :active="step" align-center>
        <el-step v-for="(item,index) in stepArr" :key="item.id+index" class="step1">
          <div slot="title" class="describe">
            <div slot="title">{{ item.title }}</div>
          </div>
          <span slot="icon" class="common pwd-step-deactive" :class="{'pwd-step':index<step}" />
        </el-step>
      </el-steps>

      <div v-if="step===1" class="step">
        <!-- <img src="@/static/img/reg/step_1.png" /> -->

        <div class="title">输入手机号</div>
        <div class="content-box">
          <div class="gray subtitle">请输入绑定手机号</div>
          <div class="flex mobile-box input-box">
            <div class="pre-mobile">
              中国(+86)
              <span class="iconfont icon-simu-steps2" />
            </div>
            <div class="flex-between" style="flex:1">
              <input
                v-model="mobile"
                type="text"
                class="mobile"
                placeholder="请输入手机号"
                maxlength="11"
                @keyup="voliMobile"
              />
              <span
                v-show="mobile"
                class="iconfont"
                :class="[mobileState ? 'icon-form-checkmark green' : 'icon-form-error red', {'green':mobileState}]"
              />
            </div>
          </div>
          <!-- 手机号错误提示 -->
          <div class="msg-error">{{ step1Error }}</div>

          <div
            class="gray subtitle"
          >点击获取验证码,短信将安全发送到安全手机{{ mobile.length==11? desensitization(mobile,4,-4):'' }}</div>
          <div class="flex-between">
            <div class="input-box msg-code-box flex-between">
              <input
                v-model="msgCode"
                class="msg-code"
                placeholder="请输入验证码"
                maxlength="6"
                @keyup="voliMsgCode"
              />
              <span
                v-show="msgCode"
                class="iconfont"
                :class="[msgCodeState ? 'icon-form-checkmark green' : 'icon-form-error red', {'brown':msgCodeState}]"
              />
            </div>
            <div
              :class="['input-box send-msg-box  btn-orange-plain',{'disable-plain' : !sendCodeAble}]"
              @click="sendMsgCode"
            >
              <span>{{ msgCodeTxt }}</span>
            </div>
          </div>
          <div class="next">
            <input
              type="button"
              :class="['btn-next btn-orange',{'disable':!step1Able}]"
              value="下一步"
              @click="checkSms"
            />
          </div>
        </div>
      </div>
      <div v-if="step===2" class="step step-2">
        <!-- <img src="@/static/img/reg/step_2.png" /> -->
        <div class="title">设置登录密码</div>
        <div class="content-box">
          <div class="gray subtitle">请设置6~16位数字、字母组合、区分大小写</div>
          <div class="input-box flex-between">
            <input
              v-model="passOne"
              maxlength="16"
              placeholder="请输入登录密码"
              :type="passOneShow ? 'text' : 'password'"
            />
            <span
              class="iconfont"
              :class="['btn ico',passOneShow ? 'icon-home-eyes-open' : 'icon-home-eyes-close']"
              @click="passOneShow = !passOneShow"
            />
          </div>
          <div class="input-box flex-between">
            <input
              v-model="passTwo"
              maxlength="16"
              placeholder="再次输入登录密码"
              :type="passTwoShow ? 'text' : 'password'"
            />
            <span
              class="iconfont"
              :class="['btn ico',passTwoShow ? 'icon-home-eyes-open' : 'icon-home-eyes-close']"
              @click="passTwoShow = !passTwoShow"
            />
          </div>
          <div class="msg-error">{{ step2Error }}</div>
          <div class="next" @click="resetPassword">
            <input type="button" :class="['btn-next btn-orange',{'disable':!step2Able}]" value="确认" />
          </div>
        </div>
      </div>
      <div v-if="step===3" class="step step-3">
        <!-- <img src="@/static/img/reg/step_3.png" /> -->
        <div class="content-box">
          <span class="ico-success" />
          <div class="success-msg">登录密码重置成功，请重新登录！</div>
          <div class="btn btn-orange-plain go-open" @click="goLogin">去登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessage, checkVerify, resetPass } from '@/api/resetPassword.js'
import { desensitization } from '@/utils/debounce.js'
export default {
  name: 'ResetPasswordOne',
  components: {},
  data() {
    return {
      verify: '',
      step: 1,
      mobile: '',
      mobileState: false,
      msgCode: '',
      msgCodeState: false,
      sendCodeAble: true,
      msgCodeTxt: '获取验证码',
      step1Error: '',
      step1Able: true,
      uuid: '',
      passOne: '',
      passOneShow: false,
      passTwo: '',
      passTwoShow: false,
      step2Error: '',
      step2Able: true,
      stepArr: [
        {
          title: '手机验证',
          id: 'shoujiyanzheng'
        },
        {
          title: '设置登录密码',
          id: 'shezhimima'
        },
        {
          title: '操作成功',
          id: 'caozuochenggong'
        }
      ]
    }
  },
  mounted() {},
  methods: {
    // 脱敏
    desensitization,
    /** 验证手机格式 */
    voliMobile() {
      let mobile = this.mobile.replace(/[^\d]/g, '')
      this.mobile = mobile
      if (/^1[3-9]\d{9}$/.test(mobile)) {
        this.mobileState = true
      } else {
        this.mobileState = false
      }
    },
    /** 验证码位数验证 */
    voliMsgCode() {
      this.msgCodeState = this.msgCode && this.msgCode.length === 6
    },
    // 获取验证码
    sendMsgCode() {
      if (!this.sendCodeAble) {
        return
      }
      if (!this.mobileState) {
        this.step1Error = '请输入正确的手机号!'
        return
      }
      this.step1Error = ''
      this.sendCodeAble = false
      this.msgCodeTxt = '正在发送……'
      getMessage(this.mobile, '4')
        .then((res) => {
          if (res.code === 200) {
            let count = 60
            this.msgCodeTxt = '重新发送(' + count + ')'
            let interval = setInterval(() => {
              count--
              if (count === 0) {
                this.msgCodeTxt = '发送验证码'
                this.sendCodeAble = true
                clearInterval(interval)
              } else {
                this.msgCodeTxt = '重新发送(' + count + ')'
              }
            }, 1000)
            this.verify = res.data
          }
        })
        .catch((err) => {
          // this.$message({
          //   message: err,
          //   type: 'warning'
          // })
          console.log('err', err)
          this.sendCodeAble = true
          this.msgCodeTxt = '发送验证码'
        })
    },
    // 下一步
    checkSms() {
      checkVerify(this.mobile, this.msgCode, '4').then((res) => {
        if (res.code === 200) {
          console.log(res)
          if (res.code === 200) {
            this.step = 2
            this.uuid = res.data
          }
        }
      })
    },
    // 重置密码
    resetPassword() {
      if (!this.passOne) {
        this.step2Error = '请输入登录密码!'
        return
      }
      if (this.passOne.length < 6) {
        this.step2Error = '密码至少6位!'
        return
      }
      if (!this.passTwo) {
        this.step2Error = '请再次输入登录密码!'
        return
      }
      if (this.passOne !== this.passTwo) {
        this.step2Error = '两次密码输入不相同,请检查!'
        return
      }
      resetPass({
        password: this.passOne,
        mobileTel: this.mobile,
        passwordCopy: this.passTwo,
        uuid: this.uuid
      }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.step = 3
        }
      })
    },
    // 去登录
    goLogin() {
      this.$router.replace({ path: '/login' })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: #f3f4f6;
}
@orange: #ce9b63;
@gray: #bdc0cb;
.flex {
  display: flex;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gray {
  color: @gray;
}
.green {
  color: #00b900;
}
.red {
  color: #e6361e;
}
.brown {
  color: #ce9b63;
}
.btn-orange {
  font-size: 16px;
  color: #c58136;
  background: linear-gradient(109.88deg, #f7d9b7 -3.02%, #eabf96 98.27%);
  padding: 1em 0;
  text-align: center;
  border-radius: 4px;
}
input {
  font-size: 15px;
}
.btn-orange-plain {
  font-size: 16px;
  color: #c58136;
  text-align: center;
  border: 1px solid @orange;
  border-radius: 5px;
  cursor: pointer;
}
.disable-plain {
  color: @gray;
  border: 1px solid @gray !important;
  cursor: not-allowed;
}

.reg {
  width: 1200px;
  background: #fff;
  margin: auto;
  font-size: 15px;
}
.step {
  width: 560px;
  margin: auto;
  padding-bottom: 60px;
  .title {
    padding: 60px;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #25293d;
  }
  .content-box {
    margin: auto;
    width: 80%;
  }
  // 输入框标题
  .subtitle {
    font-size: 14px;
    line-height: 14px;
  }
  .input-box {
    margin: 1em auto;
    border: 1px solid #e0e2e8;
    border-radius: 5px;
    padding: 1em;
    align-items: center;
    .ico {
      font-size: 16px;
      color: #bdc0cb;
    }
    & > input[type='text'],
    & > input[type='password'] {
      width: 100%;
    }
  }
  .mobile-box {
    padding: 0;
    & > div {
      padding: 0.8em;
    }
    // 地区
    .pre-mobile {
      border-right: 1px solid #e0e2e8;
      font-size: 12px;
      line-height: 29px;
      color: #25293d;
    }
    .iconfont {
      font-size: 10px;
    }
    .mobile {
      width: 270px;
      font: 20px 'microsoft yahei';
      line-height: 20px;
    }
  }
  // 字体图标
  .iconfont {
    font-size: 13px;
  }
  // 验证码输入框
  .msg-code-box {
    width: 60%;
  }
  .msg-code {
    width: 100%;
  }
  .send-msg-box {
    width: 24%;
    margin-left: 1.5em;
    border: 0.6px solid #ce9b63;
  }
  .msg-error {
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 12px;
    color: #e45247;
  }
  .inviter-box {
    padding: 1em 0;
    .ico {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .btn-next {
    width: 100%;
    font-size: 18px;
  }
}
.step-2 {
  .next {
    margin-top: 2em;
  }
}
.step-3 {
  text-align: center;
  .ico-success {
    font: bold 120px 'microsoft yahei';
    color: #00b900;
    margin: 60px auto;
    display: block;
  }
  .success-msg {
    font-size: 1.2em;
    line-height: 2em;
    margin-bottom: 1.5em;
  }
  .go-open {
    padding: 1em;
    display: block;
    width: 300px;
    margin: auto;
  }
}

// 步骤条样式
.step-title {
  margin: auto;
  padding: 130px 0 40px;
  width: 700px;
  .common {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .pwd-step-deactive {
    border: 6px solid #f3f4f6;
    background-color: #cfcfcf;
  }
  .pwd-step {
    border: 6px solid #fff5ea;
    background-color: #ce9b63;
  }
  .describe {
    margin-top: -70px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }
}
/deep/ .el-step__icon.is-text {
  border: none;
}
/deep/ .el-step__title {
  line-height: 24px;
}
// 未激活的进度
/deep/ .el-step__line {
  background-color: #e0e2e8;
}
/deep/ .el-step__head.is-wait {
  color: #f6f5f3;
}
/deep/ .el-step__title.is-wait {
  color: #c8c6c0;
}
/deep/ .el-step__title.is-finish {
  color: #ce9b63;
}
/deep/ .el-step__title.is-process {
  font-weight: normal;
  color: #c8c6c0;
}
/deep/ .el-step__head.is-process {
  color: #f6f5f3;
  font-weight: normal;
}
// 完成的进度
/deep/ .el-step__head.is-finish {
  color: #eabf96;
  border-color: #eabf96;
}
</style>
