/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(fn, time, triggleNow) {
	var t = null;
	var res;
	var debounced = function () {
		var _self = this; //保存一下this的指向
		var args = arguments; //如果返回带参数的函数，就需要保存一下arguments
		if (t) {
			clearTimeout(t) //消除计时器
		}
		if (triggleNow) {
			var exec = !t; //t为null时才能执行函数，这里取非，方便下面的操作
			t = setTimeout(function () { //计时器之前进行清除了计时器，所以这时候要加上
				t = null //延迟预计时间后，t还是应该为null，才能立即触发
			}, time)
			if (exec) { //取非后这里为真，直接执行即可
				res = fn.apply(_self, args)
			}
		} else {
			t = setTimeout(function () {
				res = fn.apply(_self, args)
			}, time)
		}
		return res;
	}

	debounced.remove = function () {
		clearTimeout(t);
		t = null
	}
	return debounced
}

export function throttle(fn, delay) {
	let canRun = true; // 通过闭包保存一个标记
	return function () {
		if (!canRun) return; // 在函数开头判断标记是否为true，不为true则return
		canRun = false; // 立即设置为false
		setTimeout(() => { // 将外部传入的函数的执行放在setTimeout中
			fn.apply(this, arguments);
			// 最后在setTimeout执行完毕后再把标记设置为true(关键)表示可以执行下一次循环了。当定时器没有执行的时候标记永远是false，在开头被return掉
			canRun = true;
		}, delay);
	};
}

/*
 * 获取当前年月日
 * */
export function nowDate() {
	const loadYear = new Date().getFullYear()
	let loadMonth = new Date().getMonth() + 1
	let loadDay = new Date().getDate()
	if (loadMonth >= 1 && loadMonth <= 9) {
		loadMonth = '0' + loadMonth
	}
	if (loadDay >= 0 && loadDay <= 9) {
		loadDay = '0' + loadDay
	}
	const date = loadYear + '-' + loadMonth + '-' + loadDay
	return date
}
/*
 * 获取前三个月
 * */
export function beforeThree() {
	const dates = new Date()
	dates.setMonth(dates.getMonth() - 3)
	var pastMonth = dates.getMonth() + 1
	var pastDay = dates.getDate()
	if (pastMonth >= 1 && pastMonth <= 9) {
		pastMonth = '0' + pastMonth
	}
	if (pastDay >= 0 && pastDay <= 9) {
		pastDay = '0' + pastDay
	}
	const endDate = dates.getFullYear() + '-' + pastMonth + '-' + pastDay
	return endDate
}
/*
 * 最近一月
 * */
export function oneMonth() {
	const dates = new Date()
	dates.setMonth(dates.getMonth() - 1)
	var pastMonth = dates.getMonth() + 1
	var pastDay = dates.getDate()
	if (pastMonth >= 1 && pastMonth <= 9) {
		pastMonth = '0' + pastMonth
	}
	if (pastDay >= 0 && pastDay <= 9) {
		pastDay = '0' + pastDay
	}
	const endDate = dates.getFullYear() + '-' + pastMonth + '-' + pastDay
	return endDate
}
/*
 * 最近半年
 * */
export function haflYear() {
	// 先获取当前时间
	var curDate = (new Date()).getTime()
	// 将半年的时间单位换算成毫秒
	var halfYear = 365 / 2 * 24 * 3600 * 1000
	// 半年前的时间（毫秒单位）
	var pastResult = curDate - halfYear
	// 日期函数，定义起点为半年前
	var pastDate = new Date(pastResult)
	var pastYear = pastDate.getFullYear()
	var pastMonth = pastDate.getMonth() + 1
	var pastDay = pastDate.getDate()
	if (pastMonth >= 1 && pastMonth <= 9) {
		pastMonth = '0' + pastMonth
	}
	if (pastDay >= 0 && pastDay <= 9) {
		pastDay = '0' + pastDay
	}
	var endDate = pastYear + '-' + pastMonth + '-' + pastDay
	return endDate
}
/*
 * 最近一年
 * */
export function reactYear() {
	var nowDate = new Date()
	var dates = new Date(nowDate)
	dates.setDate(dates.getDate() - 365)
	var seperator1 = '-'
	var year = dates.getFullYear()
	var month = dates.getMonth() + 1
	var strDate = dates.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	var currentdate = year + seperator1 + month + seperator1 + strDate
	return currentdate
}
/*
 * 当前月末日期
 * */
export function endMonth(loadYear, loadMonth) {
	const d = new Date(loadYear, Number(loadMonth) + 1, 0)
	const endDay = d.getDate()
	const endDate = loadYear + '-' + (Number(loadMonth) + 1) + '-' + endDay
	return endDate
}

/**
 * 当前日期+1
 */
export function getNextDay() {
	const date = new Date();
	date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
	const nextDay = {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate()
	}
	return nextDay
}


//数字精度问题。乘法
export function accMul(arg1, arg2) {
	var m = 0,
		s1 = arg1.toString(),
		s2 = arg2.toString();
	try {
		m += s1.split(".")[1].length
	} catch (e) {
		// console.log('e', e);
	}
	try {
		m += s2.split(".")[1].length
	} catch (e) {
		// console.log('e', e);
	}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}
//加法 
export function accAdd(arg1, arg2) {
	var r1, r2, m;
	try {
		r1 = arg1.toString().split(".")[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split(".")[1].length
	} catch (e) {
		r2 = 0
	}
	m = Math.pow(10, Math.max(r1, r2))
	return (arg1 * m + arg2 * m) / m
}
//减法 
export function Subtr(arg1, arg2) {
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split(".")[1].length
	} catch (e) {
		r2 = 0
	}
	m = Math.pow(10, Math.max(r1, r2));
	n = (r1 >= r2) ? r1 : r2;
	return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
//除法
export function accDiv(arg1, arg2) {
	let int1 = 0,
		int2 = 0,
		digits1, digits2;
	try {
		digits1 = arg1.toString().split(".")[1].length
	} catch (e) {
		digits1 = 0
	}
	try {
		digits2 = arg2.toString().split(".")[1].length
	} catch (e) {
		digits2 = 0
	}

	int1 = Number(arg1.toString().replace(".", ""))
	int2 = Number(arg2.toString().replace(".", ""))
	return (int1 / int2) * Math.pow(10, digits2 - digits1);
}

/* 保留decimal位小数，不四舍五入 */
export function formatDecimal(num, decimal) {
	num = num.toString()
	let index = num.indexOf('.')
	if (index !== -1) {
		num = num.substring(0, decimal + index + 1)
	} else {
		num = num.substring(0)
	}
	return parseFloat(num).toFixed(decimal)
}

/* 脱敏 */
export function desensitization(str, beginLen, endLen) {
	var len = str.length;
	var firstStr = str.substr(0, beginLen);
	var lastStr = str.substr(endLen);
	var middleStr = str.substring(beginLen, len - Math.abs(endLen)).replace(/[\s\S]/ig, '*');
	return firstStr + middleStr + lastStr;
}


export function popFileSelector() {
	return new Promise(resolve => {
		let input = document.createElement('input');
		input.value = '选择文件';
		input.type = 'file';
		input.onchange = event => {
				let file = event.target.files[0];
				resolve(file);
				// let file_reader = new FileReader();
				// console.log(file)
				// file_reader.onload = () => {
				// 		let fc = file_reader.result;
				// 		console.log(file_reader)
				// 		resolve(fc); // 返回文件文本内容到Promise
				// };
				// file_reader.readAsText(file, 'UTF-8');
		};
		input.click();
	});
}