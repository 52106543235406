import request from '@/utils/request'

/** 发送短信验证码
 * @param {String} phone 电话号码
 * @param {String} verifCode 验证码类型 1-注册，2-登录，3-修改手机号原手机发送验证码，4-重置登录密码 5-重置交易密码 6-修改手机号新手机号发送验证码
 * @param {String} [uuid] UUID（验证码类型5,6必传）
 * @param {String} [oldPhone] 原手机号码（验证码类型6必传）
 */
export function getMessage(phone, verifCode, uuid, oldPhone) {
  return request({
    url: '/api/sale/sms/routineSend',
    method: 'post',
    data: {
      phone, verifCode, uuid, oldPhone
    }
  })
}

/** 短信码校验
 * @param {String} phone 手机号码
 * @param {String} code 短信验证码
 * @param {String} verifCode 验证码类型 1-注册，2-登录，3-修改手机号，4-重置密码
 */
export function checkVerify(phone, code, verifCode) {
  return request({
    url: '/api/sale/sms/checkVerifCode',
    method: 'post',
    data: {
      phone, code, verifCode
    }
  })
}
/**重置密码*/
export function resetPass(data) {
  return request({
    url: '/api/sale/user/personal/resetLoginPwd',
    method: 'post',
    data
  })
}
/**注册新用户*/
export function register(pass) {
  console.log(pass)
  var data = {
    "clientSource": 1, //0:APP 1:WEB
    "password": pass.passOne,
    "passwordCopy": pass.passTwo,
    "mobileTel": pass.mobileTel,
    "registerUUID": pass.uuid,
    "custType": 1,//客户类型 0:机构  1:个人  2:产品
    "channel": 1, //渠道来源（1.自主注册 2.老客户推荐 3.客户经理推荐 4.三方渠道）
    "inviteCode": pass.inviteCode
  }
  return request({
    url: '/api/sale/customer/register',
    method: 'post',
    data: data
  })
}
